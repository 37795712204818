import { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

// import { useMediaPredicate } from 'react-media-hook';
// import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import { usePageTitle } from 'utils/pageTitle';
import DashboardLayout from 'pages/_rd_dashboard/DashboardLayout';

import { getPropertyList } from 'reducers/propertyListReducer';

import Spinner from 'components/Spinner';

import './PortfolioMap.css';


const PortfolioMap = (props) => {
	usePageTitle('Portfolio Map');
	const dispatch = useDispatch();
	// const isMobile = useMediaPredicate(MOBILE_SIZE);
	const {
		userId,
		properties,
		isLoading,
	} = useSelector(state => ({
		userId: state.user.userData?.id,
		properties: state.propertyList.propertyList?.data || [],
		isLoading: state.propertyList.isLoading,
	}));

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (userId) {
			dispatch(getPropertyList(userId));
		}
	}, [userId]);


	
	// const tileUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
	// const attribution = '&copy; <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">City of New York CC BY 4.0</a>';
	
	const tileUrl = 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png';
	const tileAttribution = 'Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL';
	
	
	// const START_ZOOM = 12;
	const Leaflet = window.L;

	const nycLat = '40.7128';
	const nycLong = '-74.0060';
	const nycCoords = [nycLat, nycLong];

	let bounds = Leaflet.latLngBounds([nycCoords]);
	let center = nycCoords;

	if (properties?.length) {
		let coords = properties?.map((property) => {
			return [property?.property?.lat, property?.property?.['long']];
		});
		bounds = Leaflet.latLngBounds(coords);
		bounds = bounds.pad(.1)
		center = [properties?.[0]?.property?.lat, properties?.[0]?.property?.['long']]
	}

	const [visibleProperties, setVisibleProperties] = useState({});
	useEffect(() => {
		if (properties?.length) {
			let visProperies = {};
			properties.forEach((property) => {
				visProperies[property?.id] = true;
			});
			setVisibleProperties(visProperies)
		}
	}, [properties]);


	const toggleProperty = (propertyId) => {
		let visProperies = Object.assign({}, visibleProperties);
		visProperies[propertyId] = !visProperies[propertyId];
		setVisibleProperties(visProperies);
	};

	return (
		<DashboardLayout>
			<div className="dashboard-portfolio-map">
				<h1>Portfolio Map</h1>
				{isLoading ? <Spinner /> :
					<div className="pb-4">
						{!!(properties?.length) && (
							<div className="map-wrapper">
								<MapContainer center={center} bounds={bounds} scrollWheelZoom={false}>
									<TileLayer url={tileUrl} attribution={tileAttribution} />
									{properties?.map((property) => {
										if (!visibleProperties[property.id]) {
											return null;
										}
										return (
											<Marker key={property?.id} position={[property?.property?.lat, property?.property?.['long']]}>
												<Popup>
													{property?.property?.address}
												</Popup>
											</Marker>
										)
									})}
								</MapContainer>
								<div className="map-property-list">
									<h4>Properties</h4>
									{properties?.map((property) => (
										<div className="form-group form-check" key={property.id}>
											<input
												type="checkbox"
												className="form-check-input"
												id={property.id}
												value={visibleProperties[property.id]}
												checked={visibleProperties[property.id]}
												onChange={() => toggleProperty(property.id)}
											/>
											<label className="form-check-label" htmlFor={property.id}>{property?.property?.address}</label>
										</div>
									))}
								</div>
							</div>
						)}
					</div>
				}
			</div>
		</DashboardLayout>
	);	


}

export default PortfolioMap;
