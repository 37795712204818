import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import DashboardLayout from 'pages/_rd_dashboard/DashboardLayout';

import { regenerateApiKey, fetchUser, updateUser, forgotPassword } from 'reducers/userReducer';
import { API_URL, setApiDomain } from 'data/services/apiConstants';
// import { getPlan } from 'utils/subscriptionPlans';
import { isAdminUser } from 'utils/subscriptionPlans';
import { isValidEmail } from 'utils/validators';
import Alert from 'components/Alert';
import Spinner from 'components/Spinner';
 

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

import './Settings.css';

const Settings = (props) => {
	usePageTitle('Account Settings');
	let dispatch = useDispatch();
	let {
		// isLoggedIn,
		apiKey,
		// planName,
		isRegeneratingApiKey,
		apiKeyRegenError,
		isLoading,
		isUpdating,
		userData,
		authToken,
		isAdmin,
		isResettingPassword,
		wasResetSuccessful,
		passwordResetMessages,
	} = useSelector(state => ({
		// isLoggedIn: !!state.user.userData?.id,
		apiKey: state.user.userData?.api_key?.api_key || '',
		// planName: getPlan(state.user.userData?.user_group),
		isRegeneratingApiKey: state.user.isRegeneratingApiKey,
		apiKeyRegenError: state.user.apiKeyRegenError,
		isLoading: state.user.isLoading || state.user.isUpdating,
		isUpdating: state.user.isUpdating,
		userData: state.user.userData,
		authToken: state.user.authToken,
		isAdmin: isAdminUser(state.user.userData),
		isResettingPassword: state.user.isResettingPassword,
		wasResetSuccessful: state.user.wasResetSuccessful,
		passwordResetMessages: state.user.passwordResetMessages,
	}));

	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [isEmailValid, setIsEmailValid] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	const [isEmailConfRequired, setIsEmailConfRequired] = useState(false);
	const [apiUrlText, setApiUrlText] = useState(API_URL);
	const [isChangingApi, setIsChangingApi] = useState(false);

	useEffect(() => {
		if (userData?.email) {
			setEmail(userData.email);
			setIsEmailValid(isValidEmail(userData.email));
		}
		if (userData?.name) {
			setName(userData.name);
		}
		if (userData?.phone) {
			setPhone(userData.phone);
		}
	}, [userData]);

	useEffect(() => {
		if (authToken) {
			dispatch(fetchUser(authToken));
		}
	}, [dispatch, authToken]);

	const onChangeEmail = (emailString) => {
		setEmail(emailString);
		setIsEmailValid(isValidEmail(emailString));
	}

	const onSubmit = () => {
		setIsSuccess(false);
		dispatch(updateUser({
			name,
			email,
			phone,
		})).then((res) => {
			if (res?.type === "UPDATE_USER_SUCCESS") {
				// console.log('res', res);
				if (res.apiResponse.email_change_verification_required) {
					setIsEmailConfRequired(true);
				} else { 
					setIsEmailConfRequired(false);
				}
				setIsSuccess(true);	
			}
		});
	};

	const userForm = (
		<div className="p-3">
			<div className="form-group mb-3">
				<label>Name</label>
				<input
					type="text"
					className="form-control"
					value={name}
					disabled={isLoading}
					onChange={ev => setName(ev.target.value)}
					placeholder="Your name"
				/>
			</div>
			<div className="contact-fields">
				<div className="form-group mb-3">
					<label>Email</label>
					<input
						type="email"
						placeholder="Email"
						className={`form-control email-input ${isEmailValid ? 'valid' : 'invalid'}`}
						value={email}
						disabled={isLoading}
						onChange={(ev) => onChangeEmail(ev.target.value)}
					/>
				</div>
				<div className="form-group mb-3">
					<label>Phone Number</label>
					<input
						type="text"
						className="form-control"
						value={phone}
						disabled={isLoading}
						onChange={ev => setPhone(ev.target.value)}
						placeholder="(555)-555-5555"
					/>
				</div>
			</div>
			<div className="form-group mb-3">
				<button className="btn btn-sm btn-primary" onClick={onSubmit}>Save</button>
			</div>
		</div>
	);

	const successAlert = (
		<Alert color={isEmailConfRequired ? 'info' : 'success'}>
			{isEmailConfRequired ? (
				<div>Email verification required to complete this update. Check your email for instructions.</div>
			) : (
				<div>User info updated successfully!</div>
			)}
		</Alert>
	);

	const accountInfoCard = (
		<div className="card">
			<div className="card-header">
				Account Info
			</div>
			<div>
				{userForm}
			</div>
		</div>
	);


	const passwordResetCard = (
		<div className="card">
			<div className="card-header">
				Update Password
			</div>
			<div className="card-body">
				{isResettingPassword && <Spinner />}
				{passwordResetMessages.map(message => (
					<Alert color={wasResetSuccessful ? 'success' : 'danger'} text={message} key={message} />
				))}
				<div className="mt-1 mb-3">
					{`An email containing a link to update your password will be sent to ${userData.email}`}
				</div>

				<div className="form-group mb-3">
					<button onClick={() => dispatch(forgotPassword(userData.email))} disabled={isResettingPassword} className="btn btn-sm btn-primary">
						Change Password
					</button>
				</div>

			</div>
		</div>
	);


	const airtableCard = (
		<div className="card">
			<div className="card-header">
				Airtable Access
			</div>
			<div className="card-body">
				{isRegeneratingApiKey && <Spinner />}
				<div className="form-group mb-4">
					{apiKeyRegenError && <Alert text={apiKeyRegenError} color="danger" />}
					<label className="">Airtable Key</label>
					<div>
						<input type="text" readOnly className="form-control api-key-input" value={apiKey} />
					</div>
				</div>
				<button disabled={isRegeneratingApiKey} className="btn btn-sm btn-primary" onClick={() => dispatch(regenerateApiKey())}>
					Regenerate Airtable Key
				</button>
				<div className="mt-3 mb-3">
					<Link to="/airtable-instructions">How to connect to Airtable</Link>
				</div>
			</div>
		</div>
	);

	// const planCard = (
	// 	<div className="card">
	// 		<div className="card-header">
	// 			Plan Details
	// 		</div>
	// 		<div className="card-body">
	// 			<div className="mb-4">
	// 				{`You're on the ${planName} plan`}
	// 			</div>
	// 			<button disabled className="btn btn-sm btn-primary" onClick={() => {}}>
	// 				Change Plan
	// 			</button>
	// 		</div>
	// 	</div>
	// )

	const setApi = (url) => {
		setIsChangingApi(true);
		setApiDomain(url);
		setTimeout(() => { //fake timeout to make it seem like something is happening
			setApiUrlText(API_URL);
			setIsChangingApi(false);
		}, 1000)
	}

	const confirmModalChildren = (newUrl) => (
		<div>
			<div className="">Are you sure you want to change the app's API URL to:</div>
			<div className="mt-1"><strong>{newUrl}</strong></div>
			<div className="mt-3">Changing this to an invalid URL could make the app unusable for you!</div>
			<div className="mt-2">This can be undone by entering the following command into the browser's developer console:</div>
			<div className="mt-2"><pre>resetApiUrl()</pre></div>
		</div>
	);

	const confirmApiChange = (newUrl) => {
		return dispatch(openModal(MODAL_TYPES.CONFIRM, { 
			color: 'danger',
			title: 'Confirm API URL Change',
			onConfirm: () => setApi(newUrl),
			children: (confirmModalChildren(newUrl)),
			confirmLabel: 'Confirm',
		}));
	};

	let apiUrlCard = (
		<div className="card">
			<div className="card-header">
				Change API Server
			</div>
			<div className="card-body">
				{isChangingApi ? <Spinner /> : 
					<div>
						<div className="mt-1 mb-1">
							{`
							This is for debugging API server migration. Change this to the URL of the API server you want to use. Including a protocol (e.g "https://") is required.
							`}
						</div>
						<div className="mt-1 mb-1">
							{`
							The current URLs for test servers are "https://api-new.violerts.com" and  "https://ecs.violerts.com". The default url is "https://api.violerts.com".
							`}
						</div>
						<div className="mt-1 mb-1">
							{`
							The URL will persist when you refresh the page or open the app in a new tab, even if you are logged out. 
							`}
						</div>
						<div className="mt-1 mb-1">
							{`
							Warning! Entering an invalid URL may make the app unusable for you!
							`}
						</div>
						<div className="mt-1 mb-1">
							{`
							This can be manually undone from the browser console by entering the command "resetApiUrl()".
							`}
						</div>
						<div className="form-group mb-4">
							<label className="">API URL</label>
							<div>
								<input type="text" className="form-control api-key-input" value={apiUrlText} disabled={isChangingApi} onChange={ev => setApiUrlText(ev.target.value)} />
							</div>
						</div>
						<button disabled={isChangingApi} className="btn btn-sm btn-primary" onClick={() => confirmApiChange(apiUrlText)}>
							Update API URL
						</button>
						<button disabled={isChangingApi} className="btn btn-sm btn-secondary mx-2" onClick={() => setApi(null)}>
							Reset to Default
						</button>
					</div>
				}
			</div>
		</div>
	);

	let content = (
		<div className="card-wrapper">
			{isSuccess && successAlert}
			{accountInfoCard}
			{passwordResetCard}
			{airtableCard}
			{isAdmin && apiUrlCard}
			{/*planCard*/}
		</div>
	);

	return (
		<DashboardLayout>
			<div className="rd-settings pb-5">
				<h1>Settings</h1>
				<div>
					{(isLoading || isUpdating) ? <Spinner /> : content}
				</div>
			</div>
		</DashboardLayout>
	);	


}

export default Settings;
